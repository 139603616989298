/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Menu, MenuButton, MenuWrapBox, Button, MenuButtonClose, Text, Clearfix, Image, Fullmap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Masáže"}>
        <Column className="css-1c6uie2 --left bg--center" style={{"paddingTop":108,"marginBottom":0,"paddingBottom":43,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"vb6rsx31h7i"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--style4 title-box--center ff--3 fs--48 w--500 lh--2" style={{"marginTop":27.693748474121097,"paddingTop":0,"marginBottom":1,"paddingBottom":0}} content={"MIRA studio"}>
              </Title>

              <Subtitle className="subtitle-box ff--3 fs--18" style={{"marginTop":0,"paddingTop":0}} content={"masáže &amp; kosmetika"}>
              </Subtitle>

              <Subtitle className="subtitle-box ff--3 fs--22 ls--12" style={{"paddingLeft":17,"paddingBottom":30.46875}} content={"<span style=\"font-style: normal;\">SIMONA BOHÁČOVÁ</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--menu pb--12 pt--12" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} menu={true}>
          
          <Menu className="--full" fullscreen={true}>
            
            <MenuButton >
            </MenuButton>

            <MenuWrapBox className="--center">
              
              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 ff--3 fs--20 btn-box--invert" style={{"backgroundColor":"rgba(235,214,139,1)"}} href={"/"} target={""} content={"<span style=\"font-weight: 700; color: var(--black);\">HLAVNÍ STRÁNKA</span>"}>
              </Button>

              <MenuButtonClose >
              </MenuButtonClose>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 ff--3 fs--20 w--400 btn-box--invert" style={{"backgroundColor":"rgba(235,214,139,1)"}} href={"https://mira-studio.cz/masaze"} target={""} content={"<span style=\"font-weight: bold; color: var(--black);\">MASÁŽE</span>"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 ff--3 fs--20 btn-box--invert" style={{"backgroundColor":"rgba(235,214,139,1)"}} url={"https://mira-studio.cz/kosmetika"} href={"https://mira-studio.cz/kosmetika"} target={""} content={"<span style=\"font-weight: bold; color: var(--black);\">KOSMETIKA</span>"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 ff--3 fs--20 w--400 btn-box--invert" style={{"backgroundColor":"rgba(235,214,139,1)"}} href={"/darkovepukazy"} target={""} content={"<span style=\"font-weight: bold; color: var(--black);\">DÁRKOVÉ POUKAZY</span>"}>
              </Button>

            </MenuWrapBox>

          </Menu>

        </Column>


        <Column className="--invert" style={{"marginTop":0,"paddingTop":null,"marginBottom":0,"paddingBottom":0,"backgroundColor":"rgba(235,214,139,1)"}}>
          
          <ColumnWrap className="column__flex el--3 flex--top" style={{"marginTop":0,"marginBottom":0}} columns={"3"}>
            
            <ColumnWrapper className="--center" style={{"paddingTop":28,"marginBottom":16,"paddingBottom":10.92498779296875}}>
              
              <Title className="title-box title-box--style2 ff--3 fs--18 title-box--invert" style={{"maxWidth":342}} content={"<a href=\"https://www.facebook.com/mirastudiobrno\" style=\"color: var(--black); font-weight: bold;\">Sledujte MIRA studio na&nbsp; FACEBOOKU</a>"}>
              </Title>

              <Text className="text-box ff--3 fs--12 text-box--invert" content={"<span style=\"color: var(--black); font-style: italic;\">Přečtěte si něco zajímavého nejen ze světa MIRA studia, ale z oblasti masáží a kosmetiky celkově.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"paddingTop":28,"marginBottom":16,"paddingBottom":6}}>
              
              <Title className="title-box title-box--style2 ff--3 fs--18 title-box--invert" style={{"maxWidth":280}} content={"<span style=\"color: var(--black); font-weight: bold;\"><a href=\"https://www.instagram.com/mirastudio_brno/?hl=cs\">Každý den v kontaktu na&nbsp; INSTAGRAMU</a></span>"}>
              </Title>

              <Text className="text-box ff--3 fs--12 text-box--invert" content={"<span style=\"color: var(--black); font-style: italic;\">Pokud rádi sledujete stories a zapojujete se do obsahu, tohle je místo pro Vás! Navíc zde vždy zveřejňuji last-minute uvolněné termíny.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"paddingTop":28,"marginBottom":16,"paddingBottom":14.350006103515623}}>
              
              <Title className="title-box title-box--style2 ff--3 fs--18 title-box--invert" style={{"maxWidth":280}} content={"<span style=\"color: var(--black); font-weight: bold;\"><a href=\"https://www.google.com/search?q=mira+studio&amp;client=opera&amp;hs=i0t&amp;sxsrf=APwXEdcv9CkCNmTCExb5JMuz5d5UOoXzoQ%3A1687005195104&amp;ei=C6iNZKT0BdCV9u8PntasiAM&amp;ved=0ahUKEwjkpY2LqMr_AhXQiv0HHR4rCzEQ4dUDCA4&amp;uact=5&amp;oq=mira+studio&amp;gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJzINCC4QrwEQxwEQigUQJzIHCCMQigUQJzIICAAQgAQQywEyCAgAEIAEEMsBMggIABCABBDLATIICAAQgAQQywEyCAgAEIAEEMsBMggIABCABBDLATIICAAQgAQQywE6CwgAEIAEELEDEIMBOgcIABCKBRBDOgsILhCKBRCxAxCDAToHCC4QigUQQzoLCAAQigUQsQMQgwE6CwguEIAEELEDEIMBOhEILhCABBCxAxCDARDHARCvAToICAAQgAQQsQM6BQguEIAEOgsILhCABBDHARCvAToLCC4QgwEQsQMQgAQ6BQgAEIAEOgsILhCvARDHARCABEoECEEYAFAAWLoYYLobaABwAXgBgAHbAYgBzQySAQUxLjkuMZgBAKABAcABAQ&amp;sclient=gws-wiz-serp#lrd=0x47733399a837f975:0xe8cf48225afd9e4b,1,,,,\">Přečtěte si recenze na GOOGLU</a></span>"}>
              </Title>

              <Text className="text-box ff--3 fs--12 text-box--invert" content={"<span style=\"color: var(--black); font-style: italic;\">Podívejte se, jaké hodnocení mi napsali zákazníci a zákaznice a jak byli s mojí službou spokojeni.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <Clearfix >
          </Clearfix>

        </Column>


        <Column style={{"marginTop":4.924995422363281,"paddingTop":0,"paddingBottom":3.556243896484375,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"z4cos82fh7"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"backgroundColor":"var(--white)"}}>
              
              <Title className="title-box title-box--style1 ff--3 fs--36 w--500 title-box--invert ls--02" content={"<span style=\"color: var(--black);\">NABÍDKA A CENÍK:</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":0,"backgroundColor":"var(--color-main-bg-2)"}} name={"uow7u16f1t"}>
          
          <ColumnWrap className="column__flex el--2 flex--stretch" style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":0}} columns={"2"}>
          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":11,"paddingBottom":10.92498779296875,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"cenik"} layout={"l22"}>
          
          <ColumnWrap className="column__flex el--2 flex--stretch" columns={"2"}>
            
            <ColumnWrapper className="pb--08 pt--08">
              
              <Title className="title-box title-box--style4 ff--3" content={"masáž celotělová"}>
              </Title>

              <Text className="text-box text-box--right ff--3 w--600" content={"1800 Kč"}>
              </Text>

              <Text className="text-box ff--3" content={"<span style=\"font-style: italic;\">uvolnění zatuhlých svalů a dokonalá relaxace</span><br>"}>
              </Text>

              <Text className="text-box text-box--right ff--3" content={"120 min"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--08 pt--08">
              
              <Title className="title-box title-box--style4 ff--3" content={"havajská masáž <br>LOMI-LOMI"}>
              </Title>

              <Text className="text-box text-box--right ff--3 w--600" content={"1400 Kč"}>
              </Text>

              <Text className="text-box ff--3" content={"<span style=\"font-style: italic;\">smyslový zážitek</span>, naprosté uvolnění a relaxace<br>"}>
              </Text>

              <Text className="text-box text-box--right ff--3" content={"90 min"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--08 pt--08">
              
              <Title className="title-box title-box--style4 ff--3" content={"aroma blahodárná masáž \"Svíčka\""}>
              </Title>

              <Text className="text-box text-box--right ff--3 w--600" content={"1400 Kč"}>
              </Text>

              <Text className="text-box ff--3" content={"<span style=\"font-style: italic;\">celotělová masáž teplou emulzí výživných olejů</span><br>"}>
              </Text>

              <Text className="text-box text-box--right ff--3" content={"90 min"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--08 pt--08">
              
              <Title className="title-box title-box--style4 ff--3" content={"intenzivní masáž šíje + zad"}>
              </Title>

              <Text className="text-box text-box--right ff--3 w--600" content={"1200 Kč"}>
              </Text>

              <Text className="text-box ff--3" content={"skandinávská technika, <span style=\"font-style: italic;\">tip na&nbsp;dárkový poukaz</span>"}>
              </Text>

              <Text className="text-box text-box--right ff--3" content={"90 min"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--08 pt--08">
              
              <Title className="title-box title-box--style4 ff--3" content={"masáž zad nebo dolních končetin"}>
              </Title>

              <Text className="text-box text-box--right ff--3 w--600" content={"950 Kč"}>
              </Text>

              <Text className="text-box ff--3" content={"<span style=\"font-style: italic;\">skandinávská uvolňující nebo relaxační</span>"}>
              </Text>

              <Text className="text-box text-box--right ff--3" content={"60 min"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--08 pt--08">
              
              <Title className="title-box title-box--style4 ff--3" content={"protimigrénová masáž"}>
              </Title>

              <Text className="text-box text-box--right ff--3 w--600" style={{"maxWidth":332}} content={"700 Kč"}>
              </Text>

              <Text className="text-box ff--3" content={"speciální masáž hlavy, šíje a oblasti lopatek"}>
              </Text>

              <Text className="text-box text-box--right ff--3" content={"40 min"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--08 pt--08">
              
              <Title className="title-box title-box--style4 ff--3" content={"masáž zad pohotovostní"}>
              </Title>

              <Text className="text-box text-box--right ff--3 w--600" style={{"maxWidth":332}} content={"700 Kč"}>
              </Text>

              <Text className="text-box ff--3" content={"rychlé uvolnění a ošetření bolavých zad"}>
              </Text>

              <Text className="text-box text-box--right ff--3" content={"40 min"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--08 pt--08">
              
              <Title className="title-box title-box--style4 ff--3" content={"&nbsp;relaxační masáž obličeje s maskou"}>
              </Title>

              <Text className="text-box text-box--right ff--3 w--600" style={{"maxWidth":332}} content={"700 Kč"}>
              </Text>

              <Text className="text-box ff--3" content={"dokonalý odpočinek, <span style=\"font-style: italic;\">tip na dárkový poukaz</span>"}>
              </Text>

              <Text className="text-box text-box--right ff--3" content={"30 min"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--08 pt--08">
              
              <Title className="title-box title-box--style4 ff--3" content={"masáž šíje"}>
              </Title>

              <Text className="text-box text-box--right ff--3 w--600" style={{"maxWidth":332}} content={"450 Kč"}>
              </Text>

              <Text className="text-box ff--3" content={"včetně protahovacích cviků"}>
              </Text>

              <Text className="text-box text-box--right ff--3" content={"30 min"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--08 pt--08">
              
              <Title className="title-box title-box--style4 ff--3" content={"pouze baňkování"}>
              </Title>

              <Text className="text-box text-box--right ff--3 w--600" content={"450 Kč"}>
              </Text>

              <Text className="text-box ff--3" content={"baňkování konkrétní namáhané oblasti&nbsp;"}>
              </Text>

              <Text className="text-box text-box--right ff--3" content={"15-25 min"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--08 pt--08">
              
              <Title className="title-box title-box--style4 ff--3" content={"baňky k jakékoliv masáži&nbsp;"}>
              </Title>

              <Text className="text-box text-box--right ff--3 w--600" content={"+ 150 Kč"}>
              </Text>

              <Text className="text-box ff--3" content={"baňkování konkrétní namáhané oblasti&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":4,"paddingBottom":3.556243896484375,"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"z4cos82fh7"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}}>
              
              <Title className="title-box title-box--style1 ff--3 fs--36 w--500 title-box--invert ls--02" content={"NA JAKÉ DRUHY MASÁŽÍ SE ZAMĚŘUJI?"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":9,"marginBottom":0,"paddingBottom":17.40625,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"0ooedpq6wyqk"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box ff--3" content={"&nbsp;Mimo klasické rekondiční, relaxační a sportovní masáže ovládám také masáž <span style=\"font-weight: bold;\">skandinávskou</span>, která ještě více prohlubuje uvolnění svalstva a přispívá k lepší celkové relaxaci. Skvěle funguje i proti <span style=\"font-weight: bold; font-style: italic;\">nespavosti</span> - vyspíte se jako nikdy předtím!<br><br>&nbsp;Dále dle individuální potřeby využívám techniku tzv. <span style=\"font-weight: bold;\">fundovaných dotyků</span> neboli trigger <span style=\"font-weight: bold;\">points (spoušťové body)</span>, kdy zatlačením na spoušťové body, které jsou lokalizovány na napjatých pruzích kosterního svalstva a přidružených fasciích mohu pomoci k odbourání bolesti. <br><br>&nbsp;V nabídce mám také tzv. <span style=\"font-weight: bold;\">baňkování</span>. Jedná se o tradiční čínskou techniku rychlého uvolnění zatuhlého svalstva pomocí speciálních baněk, které pracují na principu podtlaku. Používá se nejvíce v oblastech podél páteře a na šíji. Baňky používám bezpečné plastové s tlakovou pumpičkou. Nehrozí nebezpečí prasknutí baňky a pořezání nebo popálení ohněm, jako u baněk tradičních skleněných.\n<br><br><br><br><br>"}>
              </Text>

              <Image style={{"maxWidth":556}} src={"https://cdn.swbpg.com/t/2871/8335dba33c4b4f8d9be83eaddd99c6e6_s=860x_.jpg"} svg={false} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/2871/8335dba33c4b4f8d9be83eaddd99c6e6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2871/8335dba33c4b4f8d9be83eaddd99c6e6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2871/8335dba33c4b4f8d9be83eaddd99c6e6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2871/8335dba33c4b4f8d9be83eaddd99c6e6_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2871/8335dba33c4b4f8d9be83eaddd99c6e6_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box ff--3" content={"<span style=\"font-weight: bold;\">Protimigrénová</span> masáž funguje jako účinná prevence při bolestech hlavy a migrénách. Je to velice relaxační a komplexní bezolejová masáž hlavy, šíje a oblasti lopatek. Působením na spoušťové body při ní dochází k uvolňování svalů krční páteře a tím i k uvolnění od bolesti, odstraňuje také stres a nervozitu a zlepšuje náladu. Pro dlouhodobější účinky je nutné masáž několikrát opakovat (nejméně 3x) dle doporučení a aktuálního stavu.\n<br><br>&nbsp;<span style=\"font-weight: bold;\">Havajská masáž LOMI LOMI</span> je 90 minut naprostého uvolnění a relaxace. Masíruje se celé tělo zezadu speciální techniku a v rytmu šplouchání mořských vln - od prstů na nohách až po uši! K tomu Vám pustím autentickou havajskou relaxační hudbu a k masáži používám mandlový olej s vůní kokosu a vanilky. Smyslový zážitek!\n\n Ráda Vám pomohu s výběrem masáže tak, abyste byli maximálně spokojeni.<br><br>&nbsp;Ráda Vám pomohu s výběrem masáže tak, abyste byli <span style=\"font-weight: bold; font-style: italic;\">maximálně spokojeni.</span>\n<br><br><span style=\"font-weight: bold; text-decoration-line: underline;\">&nbsp;Neprovádím žádné druhy erotických masáží!</span><br><br>"}>
              </Text>

              <Image style={{"maxWidth":514}} src={"https://cdn.swbpg.com/t/2871/5dc1a18c9d1e4e6fa1abac7e99640f01_s=860x_fo_.jpg"} svg={false} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/2871/5dc1a18c9d1e4e6fa1abac7e99640f01_s=350x_fo_.jpg 350w, https://cdn.swbpg.com/t/2871/5dc1a18c9d1e4e6fa1abac7e99640f01_s=660x_fo_.jpg 660w, https://cdn.swbpg.com/t/2871/5dc1a18c9d1e4e6fa1abac7e99640f01_s=860x_fo_.jpg 860w, https://cdn.swbpg.com/t/2871/5dc1a18c9d1e4e6fa1abac7e99640f01_s=1400x_fo_.jpg 1400w, https://cdn.swbpg.com/t/2871/5dc1a18c9d1e4e6fa1abac7e99640f01_s=2000x_fo_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":3,"marginBottom":0,"paddingBottom":16.456253051757812,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"ab3qlt21qb"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 fs--20" content={"<span style=\"font-weight: bold;\">Proč je pro nás masáž prospěšná a proč si ji dopřávat pravidelně? </span>"}>
              </Title>

              <Text className="text-box ff--3 fs--14" style={{"maxWidth":746}} content={"Masáž ovlivňuje <span style=\"font-style: italic; font-weight: bold;\">celkový stav organismu</span>, a to jak tělesný, tak duševní. Mezi hlavní přínosy patří: prokrvení kůže, prokrvení a uvolnění svalů, odstranění svalové únavy, zmírnění či odstranění bolesti namáhaných svalů, zlepšení krevního oběhu, bojuje proti stresu - uvolňuje napětí, přináší pocit klidu a štěstí. <br><br>&nbsp;Zvláště v dnešní uspěchané době <span style=\"font-weight: bold; font-style: italic;\">je důležité pečovat o své zdraví a celkovou pohodu</span>. Je potřeba se občas zastavit a <span style=\"font-style: italic; font-weight: bold;\">věnovat trochu času jen sobě</span>. Právě masáž nám dokáže pomoci si odpočinout, uvolnit se a trošku zpomalit. <br><br>&nbsp;<span style=\"font-weight: bold; font-style: italic;\">Pravidelným docházením</span> na masáž <span style=\"font-weight: bold; font-style: italic;\">můžete předejít problémům</span> s bolestivým zatuhnutím svalů, četným migrénovým vlnám a dalším problémům, ale hlavně se budete cítit skvěle! \n\n\n\n\n\n <br><br><span style=\"font-style: italic; text-decoration-line: underline;\">A jak často by to tedy mělo být?</span> <br><span style=\"font-weight: bold; font-style: italic;\">&nbsp;Minimálně jednou za 3 týdny </span>by jsme si na masáž měli zají všichni. Časový interval se však <span style=\"font-weight: bold; font-style: italic;\">může lišit</span> vzhledem ke zdravotnímu stavu, psychickému stavu, pracovní zátěži a pohybové aktivitě individuálně každého jedince.\n<br><br>&nbsp;Masáž patří mezi nejstarší terapeutické techniky na světě a provází nás už tisíce let, proto o jejích báječných vlivech nelze pochybovat. \n\n\n\n\n\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":2,"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"15jp8019xdq"} layout={"l13"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper className="--center">
              
              <Image style={{"maxWidth":1162,"marginTop":41.5,"marginBottom":43.79998779296875}} src={"https://cdn.swbpg.com/t/2871/76324f69594e480d82e0b20361da5751_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2871/76324f69594e480d82e0b20361da5751_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2871/76324f69594e480d82e0b20361da5751_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2871/76324f69594e480d82e0b20361da5751_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2871/76324f69594e480d82e0b20361da5751_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2871/76324f69594e480d82e0b20361da5751_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Title className="title-box title-box--right ff--3 fs--26 w--500 ls--08" style={{"marginTop":0}} content={"DOPORUČUJI!"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--right ff--3 fs--14" style={{"maxWidth":280}} content={"Masáž jako dárek"}>
              </Subtitle>

              <Title className="title-box title-box--right ff--3" style={{"maxWidth":280}} content={"Potěšte své blízké dárkovým poukazem"}>
              </Title>

              <Text className="text-box text-box--right ff--3" style={{"maxWidth":370}} content={"Hýčkejte své milované a dopřejte jim čas pro sebe s relaxační masáží a profesionálním přístupem.<br><br>Poukazy lze zakoupit osobně na provozovně a to na jakoukoliv masáž dle ceníku.&nbsp;<br><br>Dárkový poukaz Vám automaticky předám ve slavnostní obálce dle aktuální nabídky."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":4,"paddingBottom":3.556243896484375,"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"z4cos82fh7"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}}>
              
              <Title className="title-box title-box--style1 ff--3 fs--36 w--500 title-box--invert ls--02" content={"JAK SE NA MASÁŽ PŘIPRAVIT?"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":11,"paddingBottom":6.5124969482421875,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"esme7nsf7ae"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--left ff--3 fs--20" content={"<span style=\"font-weight: bold; text-decoration-line: underline;\">Jak se na masáž připravit?</span>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--14" content={"✔️před masáží je vhodné si dopřát teplou sprchu, která nejen zbaví tělo potu, mazu a dalších nečistot, ale také prohřeje svaly a příjemně Vás naladí&nbsp;<br><br>✔️určitě je vhodné minimálně 2 hodiny před masáží nejíst těžká jídla, ale např. kousek ovoce si dopřát můžete, aby jste při masáži neomdlévali hladem&nbsp;<br><br>&nbsp;✔️na masáž se těšte a vyražte na cestu s předstihem, aby jste se nemuseli stresovat z případného opoždění - psychika při masážích hraje zcela zásadní roli!&nbsp;<br><br>✔️jestliže máte nějaké nové a bolestivé modřiny, prosím upozorněte mě na, to ať Vám nepůsobím zbytečnou bolest&nbsp;<br><br>&nbsp;✔️před masáží s v převlékací kabince odložte také své šperky: především náušnice, řetízky a prsteny - jde o Vaše pohodlí a bezpečnost&nbsp;<br><br>&nbsp;✔️své dlouhé vlasy si sepněte do skřipce nebo je zachyťte gumičkou do vysokého drdolu&nbsp;<br><br>✖️pokud se necítíte \"ve své kůži\" a jste nachlazení, nebo na Vás \"něco leze\" raději se z masáže omluvte a přeobjednejte se - při masáži dochází k výraznému prokrvení i orgánů a tím se celý proces začínající nemoci může značně uspíšit&nbsp;<br><br>✖️před masáží není vhodné konzumovat alkohol nebo omamné látky&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left ff--3 fs--20" content={"<span style=\"font-weight: bold; text-decoration-line: underline;\">Co dělat a nedělat po masáži?</span>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--14" content={"✔️doplňujte tekutiny - nejvhodnější je čistá voda nebo bylinkové čaje (po masáži dochází ke zvýšenému uvolňování toxinů z těla a čištění <br>organismu, proto je potřeba dbát na pitný režim)<br><br>✖️<span style=\"font-style: italic;\">odpusťte si kávu a alkoholické nápoje</span>☕️\n<br><br>✔️dopřejte si odpočinek - např.horkou vanu nebo se klidně natáhněte na gauči, svaly potřebují regenerovat a odpočívat 🛌\n<br><br>✖️<span style=\"font-style: italic;\">fyzicky se nezatěžujte - cvičení, posilování a jiné podobné aktivity se doporučují provádět až 24h po masáži</span>🏋️‍♂️\n<br><br>✔️zdržujte se v teple☀️\n<br><br>✖️<span style=\"font-style: italic;\">nevystavujte se chladu - svaly nesmí prochladnout, aby nevznikla svalová spazma, tím by se účinek masáže velice snížil</span>😞<br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"v2lxtrsj83h"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box ff--3 fs--36 w--500 title-box--invert" style={{"paddingTop":0}} content={"KONTAKTNÍ ÚDAJE A ADRESA:"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--invert" style={{"paddingTop":34,"marginBottom":0,"paddingBottom":21.681243896484375,"backgroundColor":"var(--color-main-bg-2)"}} name={"97kd8e0pha"}>
          
          <ColumnWrap className="column__flex el--4 flex--top" columns={"4"}>
            
            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":181,"marginBottom":0,"paddingBottom":4.5}} src={"https://cdn.swbpg.com/t/2871/a90f39d686814eefbc682c74343ef207_s=350x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/2871/a90f39d686814eefbc682c74343ef207_s=350x_.png 350w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box ff--3 fs--24 title-box--invert" content={"MIRA studio&nbsp;"}>
              </Title>

              <Text className="text-box ff--3 fs--16 w--500 text-box--invert mt--10" style={{"maxWidth":400}} content={"IČO: 03616568<br><br>kontaktní osoba: <br><span style=\"font-style: italic;\">Simona Boháčová</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box subtitle-box--style4 subtitle-box--left ff--3 fs--16 subtitle-box--invert" content={"Kontakt:"}>
              </Subtitle>

              <Text className="text-box text-box--left ff--3 fs--18 w--500 text-box--invert mt--02" content={"+420 736 150 609"}>
              </Text>

              <Text className="text-box ff--3 fs--16 w--500 text-box--invert mt--0 pt--0" content={"facebook: @mirastudiobrno<br>"}>
              </Text>

              <Button className="btn-box btn-box--style2 btn-box--invert" href={"https://www.facebook.com/mirastudiobrno/"} content={"FACEBOOK"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box subtitle-box--style4 ff--3 fs--16 subtitle-box--invert" content={"Provozovna:"}>
              </Subtitle>

              <Text className="text-box ff--3 fs--18 w--500 text-box--invert mt--02" content={"Lidická 38/1874\n<br>Brno 602 00"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--13 w--300 text-box--invert mt--0 pt--0" content={"Od Hlavního nádraží tramvají č.1, směr Řečkovice, zastávka Antonínská \nParkování ZDARMA ve dvoře, parkovací čip Vám zapůjčím.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"v2lxtrsj83h"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box ff--3 fs--36 w--500 title-box--invert" style={{"paddingTop":0}} content={"Jsme na Google mapách!"}>
              </Title>

              <Button className="btn-box btn-box--style2 btn-box--invert" href={"https://www.google.cz/maps/place/MIRA+studio+-+masáže+a+relaxace/@49.2113373,16.5891307,16.22z/data=!4m5!3m4!1s0x47733399a837f975:0xe8cf48225afd9e4b!8m2!3d49.2113978!4d16.5920673"} content={"ZDE"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"v2lxtrsj83h"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Fullmap name={"enqpd7znz0j"}>
        </Fullmap>

      </Layout>
    </ThemeWrapper>
  )
}